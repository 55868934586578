import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';

const tenantId = 'ottoversandat';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'ottoversand.at' },
    clientId: 'OttoversandAt',
    i18n: {
      defaultLocale: 'de-AT',
      locales: ['de-AT'],
      languages: ['de'],
    },
    tenantId,
    company: {
      name: 'Ottoversand',
      seoTitleName: 'OTTO',
    },
    headerSlots: {
      payback: true,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    search: {
      adServer: {
        mode: 'containerOnly',
      },
      isInspiringSearchEnabled: true,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
    },
    product: {
      cliplister: {
        customerId: '81856',
      },
      flixmedia: {
        distributorId: 18441,
        enabled: true,
      },
      hasScarcity: true,
    },
    installmentCalculator: {
      hasLegalHints: false,
      InstallmentCalculatorCallToActionLayout: {
        layout: 'Layout3',
        openCalculatorIn: { type: 'Modal' },
      },
      hasSpecialCreditColors: true,
    },
    order: {
      premium: { enabled: false },
      seals: {
        handelsverband: {
          enabled: true,
          url: 'https://www.handelsverband.at/fileadmin/content/Trustmark/Zertifikate/TrustmarkZertifikat_OttoGmbH.pdf',
        },
        dqs: {
          enabled: true,
          url: '/service-hilfe/ueber-uns/auszeichnungen/#lmPromo=la,3,hk,footer,fl,dqs',
        },
        oeonline: {
          enabled: true,
          url: '/service-hilfe/ueber-uns/auszeichnungen/',
        },
      },
      assets: {
        premium: {
          getPremiumImage: ({ device }: { device: string }) =>
            `https://bilder.ottoversand.at/empiriecom/io/ottoversandat/de/21kw10_OTTOup_Checkout_${device}.jpg`,
          getPremiumIcon: () =>
            'https://bilder.ottoversand.at/empiriecom/io/ottoversandat/de/premiumicon.svg',
        },
        gifs: {
          success: {
            url: 'https://bilder.ottoversand.at/i/empiriecom/io/de/thank_you_1_transparent.gif',
          },
        },
      },
      payback: {
        enabled: true,
        link: 'https://www.payback.at/partner-websites/otto?emid=6a88b25412fb27427c9b669481ac372a',
        cardNumberLength: 16,
        cardNumberHintUrl: '/fragment/inspire/servicelayer/de/payback_kartennummer_io',
      },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'de' },
          { params: { type: ['socket'] }, locale: 'de' },
        ],
      },
    },
    forms: {
      apiAgnitasUrl: {
        values: {
          sendAdditionalSubscribe: true,
        },
      },
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.changed.email', value: 'emailChanged' },
          { label: 'forms.type.subject.option.changed.data', value: 'dataChanged' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.retoure', value: 'retoure' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          { label: 'forms.type.subject.option.delivery', value: 'delivery' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.complaint.comment', value: 'complaint' },
          { label: 'forms.type.subject.option.return.data', value: 'return' },
          { label: 'forms.type.subject.option.coupons', value: 'coupons' },
          { label: 'forms.type.subject.option.newsletter', value: 'newsletter' },
          { label: 'forms.type.subject.option.other', value: 'other' },
        ],
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|199', 'unsubscribe|299', 'unsubscribe|99'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|199',
            options: [
              {
                id: 'unsubscribe-otto-lascana',
                value: 'unsubscribe|199',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe.otto-lascana',
              },
              {
                id: 'unsubscribe-lascana',
                value: 'unsubscribe|299',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe.lascana',
              },
              {
                id: 'unsubscribe-otto',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe.otto',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              type: 'pause',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              defaultValue: '7',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.ottoversand.at/service-hilfe/newsletter/abmelden/',
          voucherLink: 'https://www.ottoversand.at/service-hilfe/gutscheine-rabatte/',
        },
      },
      rateProtection: {
        de: {
          termsAvb: 'https://ottoversand.at/de/ratenschutz/avb',
          termsIpid: 'https://ottoversand.at/de/ratenschutz/ipid',
          termsVvinfo: 'https://ottoversand.at/de/ratenschutz/vvinfo',
        },
      },
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: { appUrl: 'https://app.adjust.com/xyyom52_pjauvx4' },
    tracking: {
      soastaId: 'TWCKT-SC956-AY2DD-K9G3Y-24R2M',
      tagmanagerId: 'GTM-5J9J459',
      googleSiteVerificationID: 'OTOyZGCNE4oOQ_91TV_2c3DeGnpNEsqffPwgTlli_Ds',
      msvalidateID: '313E2E7F8778335B4C29FD80AAAE5469',
      pinterestRichPinVerificationID: '2fe0193630b96620f4dbabcb080e4da1',
      schemaOrgOrganisation: {
        'de-AT': {
          addressCountry: 'AT',
          addressRegion: 'ST',
          addressLocality: 'Graz',
          alternateName: ['OTTO', 'Otto Versand', 'Ottoversand'],
          areaServed: 'AT',
          description:
            'Wir bei OTTO möchten das digitale Einkaufen zum Erlebnis machen. Unser Ziel ist es unsere KundInnen zu inspirieren und auf alle Wünsche passende Angebote zu liefern.',
          contactEmail: 'kundenservice@ottoversand.at',
          contactUrl: 'https://www.ottoversand.at/service-hilfe/kontakt',
          email: 'webmaster@ottoversand.at',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Otto GmbH',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Otto',
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/ottoversand',
            'https://www.instagram.com/ottoversand/',
            'https://www.pinterest.at/ottoversand/',
            'https://www.youtube.com/user/OTTOAustria',
            'https://www.online-shop.at/ottoversand',
          ],
          telephone: '+43-316-606-888',
          url: 'https://www.ottoversand.at',
          postalCode: '8020',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.ottoversand.at/s/',
          },
          streetAddress: 'Alte Poststraße 152',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: 'pAYrueynYRcqna7lGLoVT39kF83Wy3PYENp81Yw0QJqeTyzAjXvc1FwGOzCm',
      },
    },
    headerInformation: {
      de: {
        description:
          'OTTO - der Online Shop für Mode, Schuhe, Möbel und mehr. Gratis Versand ab 75 € | bequem online shoppen und auf Rechnung oder Raten kaufen!',
        title: 'OTTO Online Shop',
        canonical: 'https://www.ottoversand.at',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      tenantId: '1000',
    },
    dynamicYieldTracking: {
      key: '02730caa1ca3c4f3096718ef342ea9b7410b1112bf8765bbd169a7005032ce5e',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'at',
      alternatives: [
        {
          flag: 'de',
          shops: [{ link: 'https://www.otto.de' }],
        },
        {
          flag: 'ch',
          shops: [{ link: 'https://www.jelmoli-shop.ch' }],
        },
        {
          flag: 'it',
          shops: [{ link: 'https://www.bonprix.it' }],
        },
      ],
    },
    chatBot: {
      enabled: true,
      key: 'c7b6395b64e54d9885a74f886393f0957c39acb6e40048a390beeffcf05e415d',
      includePaths: ['/service-hilfe'],
      excludePaths: [
        '/service-hilfe/shopping-vorteile',
        '/service-hilfe/newsletter/anmelden',
        '/service-hilfe/newsletter/abmelden',
        '/service-hilfe/nachhaltigkeit',
        '/service-hilfe/ueber-uns',
      ],
    },
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
